<template>
	<div class="container">
		<top-header></top-header>
		<div class="supplier ">
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item to="/datalake">全球产品大数据湖</el-breadcrumb-item>
					<el-breadcrumb-item to="/supplier">全球竞争对手调研</el-breadcrumb-item>
					<el-breadcrumb-item>公司名称</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="supplier-item">
					<div class="supplier-item-title">
						<span>{{info.company_name}}</span>
						<div class="yuan"></div>
					</div>
					<div class="supplier-item-content" v-html="info.company_details"></div>
				</div>
				<div class="supplier-item capacity">
					<div class="supplier-item-title">
						<span>产能</span>
						<div class="yuan"></div>
					</div>
					<div class="supplier-item-text" v-html="info.capacity"></div>
				</div>
				<div class="supplier-item capacity">
					<div class="supplier-item-title">
						<span>供应链</span>
						<div class="yuan"></div>
					</div>
					<div class="supplier-item-text" v-html="info.supply_chain"></div>
				</div>
				<div class="supplier-item capacity" v-for="(item,index) in info.mill_liner" :key="index">
					<div class="supplier-item-title">
						<span>{{item.name}}</span>
						<div class="yuan"></div>
					</div>
					<div class="supplier-item-text" v-html="item.liner_details"></div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				info: '',
				supplierList: []
			}
		},
		created() {
			this.getinfo();
		},
		methods: {
			getinfo() {
				this.$get('front/companies/get_details', {
					id: this.$route.query.id
				}).then(res => {
					this.info = res.data.data;
				})
			},
			gotoUrl(url) {
				if (this.$route.fullPath != url && url) {
					this.$router.push(url);
					window.scroll(0, 0);
				}
			}
		}
	}
</script>
<style lang="scss">
	.supplier-item-content,.supplier-item-text{
		img{
			max-width: 100%;
		}
	}
</style>
<style lang="scss" scoped>
	body {
		background-color: #f5f5f5;
	}
	.supplier {
		margin-top: 60px;
		min-height: 804px;
		padding-top: 20px;

		&-item {
			background: #fff;
			margin: 40px 0;
			border-radius: 10px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
			padding: 30px;
			position: relative;
			text-align: center;
			&-text {
				text-align: center;
			}

			&-p {
				font-size: 15px;
				color: #666;
				display: inline-block;
				margin: 40px 0;
				text-align: left;
				line-height: 26px;
			}

			&-title {
				font-weight: 800;
				font-size: 20px;
				text-align: center;
				// border: 4px solid #215FB0;
				display: inline-block;
				position: relative;
				padding: 5px 10px 5px 7px;
				min-width: 35px;
				margin-bottom: 30px;
				// span {
				// 	margin-left: -30px;
				// 	background: #fff;
				// }

				.yuan {
					position: absolute;
					bottom: -3px;
					right: 15px;
					left: 15px;
					height: 4px;
					border-radius: 40px;
					background: #00337D;
				}
			}

			.left {
				width: 55%;
				display: inline-block;
				vertical-align: middle;

				@media screen and (min-width:320px) and (max-width:767px) {
					width: 100%;
					margin-bottom: 20px;
				}
			}

			.right {
				width: 45%;
				display: inline-block;
				vertical-align: middle;
				box-sizing: border-box;
				padding-left: 5%;
				font-size: 16px;
				color: #999;
				text-align: left;
				line-height: 25px;

				@media screen and (min-width:320px) and (max-width:767px) {
					width: 100%;
					padding-left: 0;
					font-size: 14px;
				}
			}
		}

		.capacity {
			padding: 30px 120px;

			@media screen and (min-width:320px) and (max-width:767px) {
				padding: 30px;
			}
		}
	}
</style>
